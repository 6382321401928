<!-- View service on right hand side of search -->
<template>
  <li class="service-list-item" v-if="!this.selectedService || this.selectedService === service.id">
    <h2>
      <span v-if="this.position" class="position">{{ this.position }}.</span>
      <router-link :to="{ name: 'Service', params: { id: service.id.toString() }}">
        <template v-if="service.service_provider_name">{{ service.service_provider_name }} -</template>{{ service.location_name }}
      </router-link>
    </h2>

    <router-link :to="{ name: 'Service', params: { id: service.id.toString() }}">
      <address v-if="service.address || service.town_city || service.county || service.post_code">
        <span v-if="service.address">{{ service.address }}, </span>
        <span v-if="service.town_city">{{ service.town_city }}, </span>
        <span v-if="service.county">{{ service.county }}, </span>
        <span v-if="service.post_code">{{ service.post_code }}</span>
      </address>
    </router-link>

    <span v-if="service.contact_email">
      Email: <a :href="contactEmailLink">{{ contactEmailLabel }}</a><br/>
    </span>

    <span class='referral-criteria' v-if="service.service.referral_criteria">
      <router-link :to="{ name: 'Service', params: { id: service.id.toString() }}">
      <div :inner-html.prop="service.service.referral_criteria  | truncate(100)"></div>
      </router-link>
    </span>

    <span v-if="service.contact_telephone">
      <a :href="contactTelephoneLink">{{ service.contact_telephone }}</a><br/>
    </span>

    <div v-if="supportedPostcodes.length">
      Eligible postcodes:
      <span class="mb-1" v-for="postcode in supportedPostcodes" :key="postcode">
        <span class="ml-1 badge badge-info">{{ postcode }} </span>
      </span>
    </div>

    <small v-if="service.searchResult.distance">{{ service.searchResult.distance }} miles away</small>

    <small v-if="service.is_online === 1"><i class="badge badge-info">Online Only</i></small>

    <div class="show-all">
      <span v-if="this.selectedService === service.id" v-on:click="clearSelectedService()">Show all</span>
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

Vue.filter("truncate", function (value, length) {
  if (!value) return "";
  value = value.toString();
  if (value.length > length) {
    return value.substring(0, length) + ".... <span class='small'>[more]</span>";
  } else {
    return value;
  }
});

export default {
  name: "ServiceListItem",
  props: {
    service: Object,
    position: String,
  },
  methods: {
    ...mapActions([
      'clearSelectedService',
    ])
  },
  computed: {
    ...mapGetters([
      'selectedService',
    ]),
    supportedPostcodes() {
      if (typeof this.service.supported_postcodes === 'undefined') {
        return "none found";
      }
      let postcodes = [];
      for (let i = 0; i < this.service.supported_postcodes.length; i++) {
        postcodes.push(this.service.supported_postcodes[i]['postcode']);
      }
      return postcodes;
    },
    contactEmailLabel() {
      if (!this.service.contact_email) {
        return null;
      }
      return this.service.contact_email.toLowerCase();
    },
    contactEmailLink() {
      if (!this.service.contact_email) {
        return null;
      }
      return 'mailto:' + this.service.contact_email.toLowerCase();
    },
    contactTelephoneLink() {
      if (!this.service.contact_telephone) {
        return null;
      }

      return 'tel:' + this.service.contact_telephone.replace(/ /g, '');
    },
  },
}
</script>

<style lang="scss" scoped>
.service-list-item {
  font-size: 1.06rem;
  line-height: 1.3em;
  padding: 10px;

  .referral-criteria p {
    font-size: 1rem !important;
    margin-bottom: 0.1rem !important;
  }

  h2 {
    color: var(--secondary);
    font-size: 1.06rem;
    margin-bottom: 0;

    a {
      color: var(--secondary);
    }

    .position {
      margin-left: -25px;
      margin-right: 14px;
      width: 0.7rem;
      display: inline-block;
    }
  }

  address {
    color: var(--primary);
    margin-bottom: 0;
  }


  a {
    color: #000;
    text-decoration: none;
  }


  small {
    color: #666;
    font-size: 85%;
  }

  .show-all {
    margin-top: 1em;

    span {
      cursor: pointer;
    }
  }
}
</style>
