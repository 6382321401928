<!-- Right hand side of search -->
<template>
  <div class="service-list">
    <template v-if="showServices">
      <template v-if="isValidSearch && services.length">

        <div v-if="services[0].lat == null">
          <div class="no-results">
            There are no groups or services in your local area, but nationwide online groups are available
          </div>
        </div>

        <ul>
          <ServiceListItem
              v-for="service in services"
              v-bind:key="service.id + '.' + service.searchResult.distance"
              v-bind:service="service"
              :position="service.searchResult.position"
          />
        </ul>
      </template>
      <template v-else>
        <div class="no-results">
          <h2>Unfortunately there is no local Admiral Nurse service in your area &ndash; yet.</h2>
          <p>
            Anyone, anywhere with questions or concerns about dementia can speak to our dementia specialist Admiral
            nurses by calling our Helpline on 0800 888 6678 or emailing
            <a href="mailto:helpline@dementiauk.org">helpline@dementiauk.org</a>. The Helpline is open Monday to
            Friday, 9am-9pm and at the weekends from 9am-5pm.
          </p>
          <p>
            We are working hard towards our goal of providing an Admiral Nurse for every family that needs one,
            but we still have a long way to go. If you would like to help us fundraise in your area, please contact
            our fundraising team on 020 8036 5440.
          </p>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center h-100 p-5">
        <b-spinner class="m-5" label="Loading..." variant="primary"></b-spinner>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ServiceListItem from '@/components/ServiceListItem.vue';

export default {
  name: "ServiceList",
  computed: {
    ...mapGetters({
      services: 'listServices',
      showServices: 'showServices',
      isValidSearch: 'isValidSearch',
    }),
  },
  components: {
    ServiceListItem
  },
}
</script>

<style lang="scss" scoped>
.service-list {
  background-color: #f2f2f2;
  max-height: 500px;
  height: 100%;
  overflow: scroll;

  ul {
    list-style: none;
  }

  .no-results {
    padding: 1.9rem;

    h2 {
      font-size: 1.625rem;
      line-height: 1;
      margin-bottom: 1.9rem;
    }

    a {
      color: var(--secondary);
    }
  }
}
</style>
