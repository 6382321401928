<template>
  <div class="map" v-if="showMap">
    <gmaps-map :options="mapOptions" v-on:center-changed="updateCenterLocation">
      <gmaps-marker :options="currentLocationMarker" v-if="showCurrentLocation" />
      <template v-if="isValidSearch && showServices">
        <gmaps-marker
          v-for="(serviceMarker, index) in serviceMarkers"
          :key="index"
          :options="serviceMarker.options"
          v-on:click="selectService(serviceMarker.serviceId)"
        />
      </template>
    </gmaps-map>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "Map",
  components: {
    gmapsMap,
    gmapsMarker
  },
  methods: {
    ...mapActions([
      'loadServices',
      'locate',
      'setInitialCenterLocation',
      'updateCenterLocation',
      'calculateSearchResultsDistances',
      'pruneResultsOverDistance',
      'sortSearchResults',
      'selectService',
    ]),
  },
  computed: {
    ...mapGetters([
      'applicationScope',
      'currentLocation',
      'showMap',
      'showCurrentLocation',
      'showServices',
      'isValidSearch',
      'listServices'
    ]),
    currentLocationMarkerIcon() {
      return require('../assets/' + this.applicationScope + '/location-icon.png');
    },
    searchResultMarkerIcon() {
      return require('../assets/' + this.applicationScope + '/result-icon.png');
    },
    mapOptions() {
      return {
        center: {
          ...this.currentLocation
        },
        gestureHandling: 'greedy',
        zoom: 9,
      }
    },
    currentLocationMarker() {
      return {
        icon: this.currentLocationMarkerIcon,
        position: {
          ...this.currentLocation
        },
        zIndex: 999,
      }
    },
    serviceMarkers() {
      let serviceMarkers = [];

      this.$store.getters.listServices.forEach((service) => {
        if (service.lat && service.long) {
          serviceMarkers.push({
            serviceId: service.id,
            options: {
              position: {
                lat: parseFloat(service.lat),
                lng: parseFloat(service.long)
              },
              title: service.service_provider_name + ' - ' + service.location_name,
              icon: this.searchResultMarkerIcon,
              label: {
                text: service.searchResult.position ?? '',
                color: '#ffffff',
                fontFamily: "'FSPimlicoWeb-Regular', sans-serif",
                fontSize: '1.06rem',
              },
            }
          });
        }
      });

      return serviceMarkers;
    }
  },
  mounted() {
      Promise.all([
        /* Only load all services if we don't have some already from another "view page" */
        this.listServices.length === 0 && this.loadServices(),
        this.locate()
      ]).then(() => this.setInitialCenterLocation())
        .then(() => this.calculateSearchResultsDistances())
        .then(() => this.pruneResultsOverDistance())
        .then(() => this.sortSearchResults())
        .catch(e => console.log(e.message));
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 500px;
}
</style>
