<template>
  <div class="services">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col cols="12">
          <Header :title="this.getTitle()"/>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col md="4" order="1" order-md="2">
          <ServiceList/>
        </b-col>
        <b-col md="8" order="2" order-md="1">
          <Map/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Map from '@/components/Map.vue';
import ServiceList from '@/components/ServiceList.vue';
import {APPLICATION_SCOPE} from "../config";
import {mapGetters} from "vuex";

export default {
  name: 'Services',
  components: {
    Header,
    Map,
    ServiceList
  },
  computed: {
    ...mapGetters([
      'applicationScope'
    ]),
  },
  methods: {
    getTitle() {
      return (this.applicationScope === APPLICATION_SCOPE.FAS ? "Search for young onset dementia groups and services near you" : "Search for the Admiral Nurse Services near you");
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
